import React, { useRef, useEffect, useState } from "react";
import { graphql } from "gatsby";
import GlobalStyle from "src/assets/styles/globalStyles.js";
import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import Seo from "src/components/Seo.js";
import logo from "src/assets/svg/logo.svg";
import QuestionMark from "src/assets/svg/questionMark.inline.svg";
import Sketch from "src/components/p5/SketchUseState.js";
import star from "src/assets/svg/star_half.svg";

import InfoBox from "src/components/InfoBox.js";
import Div100vh from "react-div-100vh";

const Wrapper = styled.div`
  margin: 0 20rem;
  width: calc(100vw - 40rem);
  position: relative;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  margin-top: 20rem;
  width: 100%;
  z-index: 100;

  @media (max-width: ${mediaQuery.tablet}) {
    align-items: center;
  }
`;
const Logo = styled.img`
  height: 30rem;
  margin-bottom: 150rem;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 20rem;
  }
`;

const InfoMark = styled(QuestionMark)`
  cursor: pointer;
  height: 30rem;
  margin-bottom: 150rem;
  padding: 0rem 0rem 30rem 30rem;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 25rem;
    padding: 0rem;
  }
  .dot {
    transform-origin: 50% calc(100% - 6rem);

    transition: all 0.5s;
  }
  :hover {
    .dot {
      transform: rotate(180deg);
      transition: all 0.5s;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
`;

const P5 = styled.div`
  width: 100vw;
  z-index: 0;
  position: relative;
  border-bottom: solid 3rem black;
  flex-grow: 1;
  ::after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    right: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%);
    z-index: 2;
  }
  ::before {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%) rotate(180deg);
    z-index: 2;
  }
`;

const ArtistsWrapper = styled.ul`
  width: calc(100% - 60rem);
  padding: 30rem;
  padding-bottom: 25rem;
  border-bottom: solid 3rem black;
  position: relative;
  overflow: hidden;

  @media (max-width: ${mediaQuery.tablet}) {
    height: 180rem;
    overflow: scroll;
  }
`;

const ArtistWrapper = styled.li`
  display: inline-flex;
  margin-right: 30rem;
  cursor: pointer;
  opacity: 0.2;
  transition: all 0.5s;
  &.show {
    opacity: 1;
    transition: all 0.5s;
  }
  :hover {
    opacity: 1;
    transition: all 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 20rem;
    margin-right: 0;
  }
`;

const ArtistName = styled.h3`
  font-size: 16rem;
  margin-bottom: 8rem;
  font-family: "DM Serif Display", serif;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const WorkName = styled.h4`
  font-size: 16rem;
  margin-bottom: 8rem;
`;

const Separator = styled.span`
  font-size: 16rem;
  margin: 0 8rem;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const BottomBar = styled.div`
  height: 50rem;
  font-size: 16rem;
  line-height: 45rem;
  text-align: center;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    top: -1.5rem;
    right: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(-50%);
    z-index: 2;
  }
  ::before {
    content: "";
    position: absolute;
    top: -1.5rem;
    left: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(-50%) rotate(180deg);
    z-index: 2;
  }
`;

const Artist = ({ artistName, work, onClick, index, curentWork }) => {
  return (
    <ArtistWrapper
      className={
        (index == curentWork && "show") || (-1 == curentWork && "show")
      }
      onClick={onClick}
    >
      <ArtistName>{artistName}</ArtistName>
      <Separator>|</Separator> <WorkName>{work}</WorkName>
    </ArtistWrapper>
  );
};

const IndexPage = ({ data }) => {
  const p5Container = useRef(null);
  const [p5ContainerH, setP5ContainerH] = useState(0);
  const [curentWork, setCurentWork] = useState(-1);
  const [openedInfoBox, setOpenedInfoBox] = useState(false);

  useEffect(() => {
    const height = p5Container.current.offsetHeight;
    setP5ContainerH(height);
  }, []);

  const changeCurentWork = (cur) => {
    setCurentWork(cur);
  };

  const openInfoBox = () => {
    setOpenedInfoBox(true);
  };
  const closeInfoBox = () => {
    setOpenedInfoBox(false);
  };
  return (
    <>
      <GlobalStyle />

      <Seo
        title={data.strapiMainInfo.SEO.Title}
        description={data.strapiMainInfo.SEO.Description}
        img={data.strapiMainInfo.SEO.Img[0].localFile.publicURL}
      />
      <Wrapper>
        <InfoBox
          title={data.strapiMainInfo.Info_title}
          infoText={data.strapiMainInfo.Info_text.data.Info_text}
          closeInfoBox={closeInfoBox}
          boxOpen={openedInfoBox}
          openGallery={data.strapiMainInfo.Open}
          address={data.strapiMainInfo.Address}
          mail={data.strapiMainInfo.Mail}
        />
        <TopWrapper>
          <Logo src={logo} alt="ds gallery logot" />
          <InfoMark
            onClick={() => openInfoBox()}
            // src={questionMark}
            // alt="questionMark"
          />
        </TopWrapper>
      </Wrapper>
      <Div100vh>
        <ContentWrapper>
          <P5 ref={p5Container} onClick={() => changeCurentWork(-1)}>
            <Sketch
              worksData={data.allStrapiArtistWork.edges}
              bgImg={data.strapiMainInfo.Bg_img.localFile.publicURL}
              containerH={p5ContainerH}
              curentWork={curentWork}
            />
          </P5>

          <ArtistsWrapper>
            {data.allStrapiArtistWork.edges.map((pos, index) => (
              <Artist
                onClick={() => changeCurentWork(index)}
                artistName={pos.node.Name}
                work={pos.node.Work_name}
                index={index}
                curentWork={curentWork}
              />
            ))}
          </ArtistsWrapper>
          <BottomBar>
            {data.strapiMainInfo.Open
              ? "teraz jesteśmy otwraci 😀"
              : "teraz jesteśmy zamknięci 😔"}
          </BottomBar>
        </ContentWrapper>
      </Div100vh>
    </>
  );
};

export default IndexPage;

export const IndexQuery = graphql`
  query MyQuery {
    strapiMainInfo {
      Open
      Info_title
      Address
      Mail
      Info_text {
        data {
          Info_text
        }
      }
      Bg_img {
        localFile {
          publicURL
        }
      }
      SEO {
        Description
        Title
        Img {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiArtistWork {
      edges {
        node {
          Name
          Work_name
          Image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
