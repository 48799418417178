import React from "react";
const { useState } = React;

const Sketch = React.lazy(() => import("react-p5"));

function SketchF({ worksData, bgImg, containerH, curentWork }) {
  let showN = curentWork;
  let speedTarget = 0;
  let rotTarget = 0;
  let zoomTarget = 1;

  const [newAngle, setNewAngle] = useState(0);
  const [newSpeed, setNewSpeed] = useState(0);
  const [newZoom, setNewZoom] = useState(1);
  const [newRot, setNewRot] = useState(0.5);
  const [newBg, setNewBg] = useState();
  const [newPg, setNewPg] = useState();

  const [newImgs, setNewImgs] = useState([]);

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(p5.windowWidth, containerH, p5.WEBGL).parent(
      canvasParentRef
    );
    p5.background(255);
    setNewPg(p5.createGraphics(p5.windowWidth * 0.25, p5.windowWidth * 0.25));
  };

  const draw = (p5) => {
    p5.translate(0, 50, 0);
    show(p5);
    worksShow(p5);
    var today = new Date(new Date().getTime()).toLocaleTimeString("pl", {
      timeZone: "Europe/Warsaw",
    });
    if (p5.frameCount > 10) {
      newPg.pixelDensity(4);
      newPg.textSize(p5.windowWidth * 0.004);
      newPg.fill(0, 0, 0);
      newPg.clear();
      newPg.fill(0, 0, 0);
      newPg.text(today, p5.windowWidth * 0.171, p5.windowWidth * 0.1565);
    }

    p5.push();
    p5.rotateX(p5.PI / 2);
    p5.translate(0, 0, p5.windowWidth * -0.025);
    p5.texture(newBg);
    p5.plane(p5.windowWidth * 0.25, p5.windowWidth * 0.25);
    p5.pop();
    p5.push();
    p5.rotateX(p5.PI / 2);
    p5.translate(0, 0, p5.windowWidth * -0.025);
    if (p5.frameCount > 10) {
      p5.texture(newPg);
      p5.plane(p5.windowWidth * 0.25, p5.windowWidth * 0.25);
    }
    p5.pop();
  };

  // const windowResized = (p5) => {
  //   vechicles = [];
  //   p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
  //   for (var y = 0; y < p5.windowHeight + squareSize; y += squareSize) {
  //     for (var x = 0; x < p5.windowWidth; x += squareSize) {
  //       if (odd) {
  //         fillIt = true;
  //         odd = false;
  //       } else {
  //         fillIt = false;

  //         odd = true;
  //       }
  //       let vechicle = new Vechicle(x, y, squareSize, fillIt, p5);
  //       vechicles.push(vechicle);
  //     }
  //   }
  // };

  function show(p5) {
    p5.background(255);
    p5.ambientLight(100);
    p5.pointLight(190, 190, 190, 0, -20, 700);

    speedTarget = p5.map(p5.mouseX, 0, p5.width, -0.02, 0.02);

    if (p5.mouseY < containerH) {
      setNewSpeed(p5.lerp(newSpeed, speedTarget, 0.05));
      setNewAngle(newAngle - newSpeed * 0.8);
    }

    rotTarget = p5.map(
      p5.constrain(p5.mouseY, 0, containerH),
      p5.height,
      0,
      0.05,
      -1
    );

    setNewRot(p5.lerp(newRot, rotTarget, 0.05));
    zoomTarget = p5.map(
      p5.constrain(p5.mouseY, 0, containerH),
      p5.height,
      0,
      1,
      2.5
    );

    setNewZoom(p5.lerp(newZoom, zoomTarget, 0.1));

    if (p5.windowWidth < 768) {
      speedTarget = 0.004;
      rotTarget = -0.1;
      zoomTarget = 2.8;

      setNewSpeed(p5.lerp(newSpeed, speedTarget, 0.05));
      setNewAngle(newAngle - newSpeed * 0.8);
      setNewRot(p5.lerp(newRot, rotTarget, 0.05));
      setNewZoom(p5.lerp(newZoom, zoomTarget, 0.1));
    }

    p5.rotateX(newRot);
    p5.rotateY(newAngle);
    p5.scale(newZoom);
  }

  function worksShow(p5) {
    p5.noStroke();

    for (let i = 0; i < newImgs.length; i++) {
      let ang = (i * p5.TWO_PI) / newImgs.length;
      p5.push();
      p5.rotateY(ang);
      p5.translate(p5.width / 4, 0, 0);
      p5.translate(0, (p5.height * -1) / 20, 0);

      if (p5.windowWidth < 768) {
        p5.rotateY(p5.PI / 2);
      } else {
        if (showN == i) {
          p5.tint(255, 255);
          p5.rotateY(p5.PI / 2);
        } else {
          p5.tint(255, 10);
        }
        if (showN < 0) {
          p5.tint(255, 255);
        }
      }

      p5.texture(newImgs[i]);
      let wrokProportion = newImgs[i].width / newImgs[i].height;
      let workW = p5.width / 20;
      let workH = workW / wrokProportion;

      p5.plane(workW, workH);

      p5.pop();
    }
  }

  function preload(p5) {
    setNewBg(p5.loadImage(bgImg));
    worksData.map((pos, index) => {
      setNewImgs((newImgs) => [
        ...newImgs,
        p5.loadImage(pos.node.Image.localFile.publicURL),
      ]);
    });
  }
  ///

  const isSSR = typeof window === "undefined";
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <Sketch setup={setup} draw={draw} preload={preload} />;
        </React.Suspense>
      )}
    </>
  );
}

export default SketchF;
