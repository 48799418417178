import React, { useRef, useEffect, useState } from "react";
import Draggable from "react-draggable";
import styled from "styled-components";
import Close from "src/assets/svg/close.inline.svg";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import ReactMarkdown from "react-markdown";

const InfoBoxWrapper = styled.div`
  position: absolute;
  top: 100rem;
  right: 100rem;
  z-index: 999;
  display: none;
  &.show {
    display: block;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    right: 10rem;
    top: 80rem;
  }
`;

const InnerBox = styled.div`
  height: 100%;
  width: 620rem;
  background-color: white;
  border: solid 3rem black;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const ShadowWrapper = styled.div`
  height: calc(100% + 10rem);
  width: calc(100% + 10rem);
  background-image: linear-gradient(
    to right bottom,
    rgb(143, 255, 91),
    rgb(182, 255, 151),
    rgb(100, 94, 128),
    rgb(57, 52, 93),
    rgb(13, 14, 59)
  );
  filter: blur(10rem);
  position: absolute;
  z-index: -1;
  top: -5rem;
  left: -5rem;
  opacity: 0.5;
`;

const BoxTop = styled.div`
  padding: 20rem;
  width: calc(100% - 40rem);
  border-bottom: solid 3rem black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
`;

const BoxTitle = styled.h2`
  font-size: 26rem;
  font-family: "DM Serif Display", serif;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 22rem;
  }
`;

const MidBox = styled.div`
  margin: 20rem;
  max-height: 190rem;
  overflow-y: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 11rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: black;
    border-right: 9rem #fff solid;
    border-left: 1rem #fff solid;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
    border-right: 8rem #fff solid;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    /* background: #555; */
  }
  @media (max-width: ${mediaQuery.tablet}) {
    ::-webkit-scrollbar {
      width: 5rem;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-right: 3rem #fff solid;
      border-left: 1rem #fff solid;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: black;
      border-right: 2rem #fff solid;
    }
  }
`;

const InfoText = styled.p`
  line-height: 1.4;
  width: 80%;
  .marginP {
    font-size: 12rem;
    p {
      margin-bottom: 10px;
      font-size: 12rem;
    }
    strong {
      font-weight: bold;
      font-size: inherit;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const BotomBox = styled.div`
  border-top: solid 3rem black;
  padding: 20rem;
  margin-top: 20rem;
`;

const BottomTopRow = styled.div`
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const BottomBottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BottomLink = styled.a`
  font-size: 12rem;
  font-weight: bold;
  z-index: 100;
  cursor: pointer;
  text-decoration: none;
`;

const BottomAdress = styled.a`
  font-size: 12rem;
  font-weight: bold;
  z-index: 100;
  margin-right: 15rem;
`;

const OpenInfoText = styled.p`
  font-size: 12rem;
  font-weight: bold;
  width: 200rem;
  line-height: 1.3;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const We3 = styled.p`
  font-size: 12rem;
  opacity: 0.3;
  a {
    font-size: inherit;
    text-decoration: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20rem;
  }
`;

const StyledClose = styled(Close)`
  transition: all 0.5s;
`;

const CloseWrappe = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 12rem;
    font-weight: bold;
    margin-right: 10rem;
  }
  :hover {
    ${StyledClose} {
      transform: rotate(90deg);
      transition: all 0.5s;
    }
  }
`;

const InfoBox = ({
  title,
  infoText,
  closeInfoBox,
  boxOpen,
  openGallery,
  address,
  mail,
}) => {
  const isBrowser = typeof window !== "undefined";
  let intViewportWidth = 0;

  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }

  return (
    <Draggable disabled={intViewportWidth < 768 && isBrowser && true}>
      {/* // <Draggable> */}
      <InfoBoxWrapper className={boxOpen && "show"}>
        <ShadowWrapper></ShadowWrapper>

        <InnerBox>
          <BoxTop>
            <BoxTitle>{title}</BoxTitle>
            <CloseWrappe onClick={closeInfoBox}>
              <span>close</span>
              <StyledClose />
            </CloseWrappe>
          </BoxTop>
          <MidBox>
            <InfoText>
              <ReactMarkdown
                className={"marginP"}
                children={infoText}
                linkTarget={"_blank"}
              />
            </InfoText>
          </MidBox>
          <BotomBox>
            <BottomTopRow>
              <BottomAdress>{address}</BottomAdress>
              <BottomLink href={`mailto:${mail}`}>{mail}</BottomLink>
            </BottomTopRow>
            <BottomBottomRow>
              <OpenInfoText>
                {openGallery
                  ? "Różnie bywa z godzinami otwarcia ale teraz jesteśmy otwarci 😀"
                  : "Różnie bywa z godzinami otwarcia ale teraz jesteśmy zamknięci 😔"}
              </OpenInfoText>
              <We3>
                Projekt i wdrożenie:{" "}
                <a href="https://we3studio.pl/" target="_blank">
                  we3studio.pl
                </a>
              </We3>
            </BottomBottomRow>
          </BotomBox>
        </InnerBox>
      </InfoBoxWrapper>
    </Draggable>
  );
};

export default InfoBox;
